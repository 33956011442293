import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";
import { observer } from "mobx-react";
import Icon from "../Assets/Icons/Icon";
import { AddCommaAmountNumber, AddDotYYYYMMDD, ConvertISOFormatDate } from "../Utils/ConvertNumberFormat";
import { Select } from "react-dropdown-select";
import { v4 as uuidv4 } from "uuid";
import { LoadComapanyData } from "../Utils/LoadCompanyData";
import axios from "axios";

const InvestorCompanyShareholderReport = observer(
  ({
    setIsVisibleReport,
    reportYearMonth,
    setReportYearMonth,
    reportName,
    setReportName,
    newMembershipRegistration,
    setNewMembershipRegistration,
    newPaidMember,
    setNewPaidMember,
    accumulatedPaidMember,
    setAccumulatedPaidMember,
    breakawayCustomer,
    setBreakawayCustomer,
    monthlyRecurringRevenue,
    setMonthlyRecurringRevenue,
    churnMonthlyRecurringRevenue,
    setChurnMonthlyRecurringRevenue,
    netMonthlyRecurringRevenue,
    setNetMonthlyRecurringRevenue,
    totalBalance,
    setTotalBalance,
    investmentFund,
    setInvestmentFund,
    supportFund,
    setSupportFund,
    totalIncome,
    setTotalIncome,
    incomeData,
    setIncomeData,
    totalSpending,
    setTotalSpending,
    fixedCost,
    setFixedCost,
    variableCost,
    setVariableCost,
    followUpInvestments,
    setFollowUpInvestments,
    subsidies,
    setSubsidies,
    keyMonthlyPlan,
    setKeyMonthlyPlan,
    sharedIssue,
    setSharedIssue,
    setIsLoading,
  }) => {
    const userData = LoadComapanyData("userData");
    const currentDate = new Date().toISOString();

    const clearReportData = () => {
      setReportName("");
      setReportYearMonth("");
      setNewMembershipRegistration("");
      setNewPaidMember("");
      setAccumulatedPaidMember("");
      setBreakawayCustomer("");
      setMonthlyRecurringRevenue("");
      setChurnMonthlyRecurringRevenue("");
      setNetMonthlyRecurringRevenue("");
      setTotalBalance("");
      setInvestmentFund("");
      setSupportFund("");
      setTotalIncome("");
      setIncomeData([]);
      setTotalSpending("");
      setFixedCost("");
      setVariableCost("");
      setFollowUpInvestments([]);
      setSubsidies([]);
      setKeyMonthlyPlan("");
      setSharedIssue("");
    };

    const reportData = {
      IssueDate: ConvertISOFormatDate(currentDate),
      ReportName: reportName,
      ReportYearMonth: reportYearMonth,
      InvesteeCompanyName: userData.companyName,
      ID: uuidv4(),
      MonthlyCustomerIndicators: {
        MembershipIndicators: {
          NewMembershipRegistration: newMembershipRegistration,
          NewPaidMember: newPaidMember,
          AccumulatedPaidMember: accumulatedPaidMember,
        },
        EarningsIndicators: {
          BreakawayCustomer: breakawayCustomer,
          MonthlyRecurringRevenue: monthlyRecurringRevenue,
          ChurnMonthlyRecurringRevenue: churnMonthlyRecurringRevenue,
          NetMonthlyRecurringRevenue: netMonthlyRecurringRevenue,
        },
      },
      MonthlyCashFlow: {
        MonthlyBankBookBalance: {
          TotalBalance: totalBalance,
          InvestmentFund: investmentFund,
          SupportFund: supportFund,
        },
        MonthlyIncome: {
          TotalIncome: totalIncome,
          IncomeData: incomeData,
        },
        MonthlySpending: {
          TotalSpending: totalSpending,
          FixedCost: fixedCost,
          VariableCost: variableCost,
        },
      },
      MonthlyFollowUpInvestmentsAndSubsidies: {
        FollowUpInvestments: followUpInvestments,
        Subsidies: subsidies,
      },
      ShareKeyManagementIssuesMonthly: {
        KeyMonthlyPlan: keyMonthlyPlan,
        SharedIssue: sharedIssue,
      },
    };

    const fieldLabelMap = {
      ReportName: "주주보고서 명",
      ReportYearMonth: "주주보고서 시기",
      "MonthlyCustomerIndicators.MembershipIndicators.NewMembershipRegistration": "신규 회원 가입",
      "MonthlyCustomerIndicators.MembershipIndicators.NewPaidMember": "신규 유료 회원 수",
      "MonthlyCustomerIndicators.MembershipIndicators.AccumulatedPaidMember": "누적 유료 회원 수",
      "MonthlyCustomerIndicators.EarningsIndicators.BreakawayCustomer": "이탈 고객 수",
      "MonthlyCustomerIndicators.EarningsIndicators.MonthlyRecurringRevenue": "MRR (월간 반복 매출)",
      "MonthlyCustomerIndicators.EarningsIndicators.ChurnMonthlyRecurringRevenue": "Churn MRR (이탈 MRR)",
      "MonthlyCustomerIndicators.EarningsIndicators.NetMonthlyRecurringRevenue": "Net MRR (순 MRR)",
      "MonthlyCashFlow.MonthlyBankBookBalance.TotalBalance": "총 잔액",
      "MonthlyCashFlow.MonthlyBankBookBalance.InvestmentFund": "투자비",
      "MonthlyCashFlow.MonthlyBankBookBalance.SupportFund": "지원비",
      "MonthlyCashFlow.MonthlyIncome.TotalIncome": "총 수입",
      "MonthlyCashFlow.MonthlyIncome.IncomeData": {
        Title: (index) => `수입 상세 항목 ${index + 1} 이름 `,
        Income: (index) => `수입 상세 항목 ${index + 1} 금액`,
      },
      "MonthlyCashFlow.MonthlySpending.TotalSpending": "총 지출",
      "MonthlyCashFlow.MonthlySpending.FixedCost": "고정비",
      "MonthlyCashFlow.MonthlySpending.VariableCost": "변동비",
      "MonthlyFollowUpInvestmentsAndSubsidies.FollowUpInvestments": {
        FundName: (index) => `후속 투자 ${index + 1} 펀드(투자사) 명`,
        StockAmount: (index) => `후속 투자 ${index + 1} 주식수`,
        StockPrice: (index) => `후속 투자 ${index + 1} 주식 단가`,
        InvestmentMoney: (index) => `후속 투자 ${index + 1} 투자금`,
        PreInvestmentMoney: (index) => `후속 투자 ${index + 1} Pre 투자금`,
        PostInvestmentMoney: (index) => `후속 투자 ${index + 1} Post 투자금`,
        InvestmentPhase: (index) => `후속 투자 ${index + 1} 투자 단계`,
        InvestmentDate: (index) => `후속 투자 ${index + 1} 투자일`,
      },
      "MonthlyFollowUpInvestmentsAndSubsidies.Subsidies": {
        HostOrganizationName: (index) => `지원 [${index + 1}] 주관기관`,
        ProgramName: (index) => `지원 ${index + 1} 프로그램명`,
        SupportFund: (index) => `지원 ${index + 1} 지원금`,
        SupportDate: (index) => `지원 ${index + 1} 지원일`,
      },
    };

    const optionalFields = [
      "ShareKeyManagementIssuesMonthly.KeyMonthlyPlan",
      "ShareKeyManagementIssuesMonthly.SharedIssue",
      "MonthlyFollowUpInvestmentsAndSubsidies.FollowUpInvestments[].Note",
      "MonthlyFollowUpInvestmentsAndSubsidies.Subsidies[].Note",
      "MonthlyFollowUpInvestmentsAndSubsidies.FollowUpInvestments[].PreInvestmentMoney",
      "MonthlyFollowUpInvestmentsAndSubsidies.FollowUpInvestments[].PostInvestmentMoney",
    ];

    const onClickConfirm = async (data) => {
      let emptyFields = [];
      const checkFields = (obj, parentKey = "") => {
        Object.keys(obj).forEach((key) => {
          const fullKey = parentKey ? `${parentKey}.${key}` : key;
          const isOptionalField = optionalFields.some((optionalField) => {
            const fieldPattern = optionalField.replace(/\[\]/g, "\\[\\d+\\]");
            const regex = new RegExp(`^${fieldPattern}$`);
            return regex.test(fullKey);
          });

          if (isOptionalField) return;

          if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
            checkFields(obj[key], fullKey);
          } else if (Array.isArray(obj[key])) {
            obj[key].forEach((item, index) => {
              Object.keys(item).forEach((fieldKey) => {
                const fullArrayKey = `${fullKey}[${index}].${fieldKey}`;
                const isOptionalArrayField = optionalFields.some((optionalField) => {
                  const fieldPattern = optionalField.replace(/\[\]/g, `\\[${index}\\]`);
                  const regex = new RegExp(`^${fieldPattern}$`);
                  return regex.test(fullArrayKey);
                });

                if (!item[fieldKey] && !isOptionalArrayField) {
                  const fieldLabel = fieldLabelMap[fullKey]?.[fieldKey]?.(index) || fullArrayKey;
                  emptyFields.push(fieldLabel);
                }
              });
            });
          } else if (!obj[key]) {
            const fieldLabel = fieldLabelMap[fullKey] || fullKey;
            emptyFields.push(fieldLabel);
          }
        });
      };

      checkFields(data);

      if (emptyFields.length > 0) {
        alert(`아래의 값을 입력해주세요.\n${emptyFields.map((field) => `- ${field}`).join("\n")}`);
      } else {
        try {
          setIsLoading(true);
          const endPoint = "https://dml1u35aa3.execute-api.ap-northeast-2.amazonaws.com/product/contrack/shareholder-report";
          const response = await axios.post(endPoint, {
            email: userData.companyEmail,
            data: reportData,
          });
          if (response.data.status === "주주보고서 생성 완료") {
            clearReportData();
            setIsLoading(false);
            setIsVisibleReport(false);
            alert("주주보고서 작성이 완료되었습니다.");
          } else {
            setIsLoading(false);
            alert("주주보고서 작성을 실패하였습니다.\n다시 한번 시도해주세요.");
          }
        } catch (error) {
          setIsLoading(false);
          console.log("Shareholder Report Issue Error : ", error);
          alert("주주보고서 작성 통신을 실패하였습니다.\n다시 한번 시도해주세요.");
        }
      }
    };

    const handleInvestmentInputChange = (e, index) => {
      const { name, value } = e.target;
      const validNames = ["StockAmount", "StockPrice", "InvestmentMoney", "PreInvestmentMoney", "PostInvestmentMoney"];
      let updatedValue = value;
      if (validNames.includes(name)) {
        updatedValue = value.replace(/\D/g, "");
      } else if (name === "InvestmentDate") {
        updatedValue = value.replace(/\D/g, "").slice(0, 8);
      }
      setFollowUpInvestments((prevInvestments) =>
        prevInvestments.map((investment, i) => (i === index ? { ...investment, [name]: updatedValue } : investment))
      );
    };

    const handleInvestmentPhaseInputChange = (name, value, index) => {
      setFollowUpInvestments((prevInvestments) =>
        prevInvestments.map((investment, i) => (i === index ? { ...investment, [name]: value } : investment))
      );
    };

    const handleDeleteInvestment = (index) => {
      setFollowUpInvestments((prevInvestments) => prevInvestments.filter((_, i) => i !== index));
    };

    const handleDeleteSubside = (index) => {
      setSubsidies((prevSubsidies) => prevSubsidies.filter((_, i) => i !== index));
    };

    const handleDeleteIncomeDats = (index) => {
      setIncomeData((prevIncomeData) => prevIncomeData.filter((_, i) => i !== index));
    };

    const addInvestment = () => {
      setFollowUpInvestments((prevInvestments) => [
        ...prevInvestments,
        {
          FundName: "",
          StockAmount: "",
          StockPrice: "",
          InvestmentMoney: "",
          PreInvestmentMoney: "",
          PostInvestmentMoney: "",
          InvestmentPhase: "",
          InvestmentDate: "",
          Note: "",
          ID: uuidv4(),
        },
      ]);
    };

    const handleSubsidiesInputChange = (e, index) => {
      const { name, value } = e.target;
      let updatedValue = value;
      if (name === "SupportFund") {
        updatedValue = value.replace(/\D/g, "");
      } else if (name === "SupportDate") {
        updatedValue = value.replace(/\D/g, "").slice(0, 8);
      }
      setSubsidies((prevSubsidies) => prevSubsidies.map((subside, i) => (i === index ? { ...subside, [name]: updatedValue } : subside)));
    };

    const addSubside = () => {
      setSubsidies((prevSubsidies) => [
        ...prevSubsidies,
        {
          HostOrganizationName: "",
          ProgramName: "",
          SupportFund: "",
          SupportDate: "",
          Note: "",
          ID: uuidv4(),
        },
      ]);
    };

    const handleIncomeDataInputChange = (e, index) => {
      const { name, value } = e.target;
      const updatedValue = name === "Income" ? value.replace(/\D/g, "") : value;
      setIncomeData((prevSubsidies) => prevSubsidies.map((subside, i) => (i === index ? { ...subside, [name]: updatedValue } : subside)));
    };

    const addIncomeData = () => {
      setIncomeData((prevIncomeData) => [
        ...prevIncomeData,
        {
          Title: "",
          Income: "",
          ID: uuidv4(),
        },
      ]);
    };

    const investmentPhaseOptions = [
      {
        value: "Seed",
        label: "Seed",
      },
      {
        value: "Pre A",
        label: "Pre A",
      },
      {
        value: "Series A",
        label: "Series A",
      },
      {
        value: "Pre B",
        label: "Pre B",
      },
      {
        value: "Series B",
        label: "Series B",
      },
      {
        value: "Pre C",
        label: "Pre C",
      },
      {
        value: "Series C",
        label: "Series C",
      },
      {
        value: "Pre D",
        label: "Pre D",
      },
      {
        value: "Series D",
        label: "Series D",
      },
    ];

    const handleNumberInputChange = (value, setValue, numberLimit) => {
      let updatedValue = value;
      if (numberLimit) {
        updatedValue = value.replace(/\D/g, "").slice(0, numberLimit);
      } else {
        updatedValue = value.replace(/\D/g, "");
      }
      setValue(updatedValue);
    };

    const formatReportYearMonthDateString = (dateString) => {
      if (dateString.length === 6) {
        const year = dateString.slice(0, 4); // 앞 4자리
        const month = dateString.slice(4, 6); // 뒤 2자리
        return `${year}년 ${month}월`;
      }
      return dateString;
    };

    return (
      <div className="Signin_CompleteModal_BG">
        <div className="ContractInput_Modal_CT">
          <div className="ContractInput_Close_CT">
            <div className="CheckList_InfoModal_Title_Copy" style={{ color: reportName.length > 0 ? "rgb(70, 70, 70)" : "#AAAAAA" }}>
              {reportName.length > 0 ? reportName : "주주보고서 명을 입력해주세요."}
            </div>
            <div className="CheckList_InfoModal_Icon_CT">
              <button className="CheckList_Close_Button" onClick={() => setIsVisibleReport(false)}>
                <Icon name={"Close"} size={"24"} />
              </button>
            </div>
          </div>
          <div className="ContractInput_Contents_CT">
            <div className="ContractInput_Left_CT">
              <div className="ContractInput_Left_Content_CT">
                {reportYearMonth.length > 0 ? (
                  <div className="ContractInput_WidthTitle_Copy" style={{ fontWeight: "500", marginBottom: "24px", color: "#3769FC" }}>
                    <span style={{ color: "rgb(70, 70, 70)" }}>주주보고서 시기 | </span>
                    {formatReportYearMonthDateString(reportYearMonth)}
                  </div>
                ) : (
                  <div className="ContractInput_WidthTitle_Copy" style={{ fontWeight: "500", marginBottom: "24px", color: "#AAAAAA" }}>
                    주주보고서 시기를 입력해주세요.
                  </div>
                )}

                <div className="ContractInput_WidthTitle_Copy">월간 고객 지표</div>
                <div className="ContractInput_Divider" />
                <div className="ContractInput_WidthTitle_Copy" style={{ color: "#3769FC", marginBottom: "12px" }}>
                  회원 지표
                </div>
                <div className="ContractInput_WidthBody_Copy">
                  신규 회원가입 |{" "}
                  <span style={{ color: "rgb(55, 105, 252)" }}>
                    {newMembershipRegistration.length > 0 ? `${AddCommaAmountNumber(newMembershipRegistration)} 명` : ""}
                  </span>
                </div>
                <div className="ContractInput_WidthBody_Copy">
                  신규 유료 회원 수 |{" "}
                  <span style={{ color: "rgb(55, 105, 252)" }}>{newPaidMember.length > 0 ? `${AddCommaAmountNumber(newPaidMember)} 명` : ""}</span>
                </div>
                <div className="ContractInput_WidthBody_Copy">
                  누적 유료 회원 수 |{" "}
                  <span style={{ color: "rgb(55, 105, 252)" }}>
                    {accumulatedPaidMember.length > 0 ? `${AddCommaAmountNumber(accumulatedPaidMember)} 명` : ""}
                  </span>
                </div>
                <div className="ContractInput_Divider" style={{ backgroundColor: "#E3E3E3" }} />
                <div className="ContractInput_WidthTitle_Copy" style={{ color: "#3769FC", marginBottom: "12px" }}>
                  수익 지표
                </div>
                <div className="ContractInput_WidthBody_Copy">
                  이탈 고객 수 |{" "}
                  <span style={{ color: "rgb(55, 105, 252)" }}>
                    {breakawayCustomer.length > 0 ? `${AddCommaAmountNumber(breakawayCustomer)} 명` : ""}
                  </span>
                </div>
                <div className="ContractInput_WidthBody_Copy">
                  MRR (월간 반복 매출) |{" "}
                  <span style={{ color: "rgb(55, 105, 252)" }}>
                    {monthlyRecurringRevenue.length > 0 ? `${AddCommaAmountNumber(monthlyRecurringRevenue)} 원` : ""}
                  </span>
                </div>
                <div className="ContractInput_WidthBody_Copy">
                  Churn MRR (이탈 MRR) |{" "}
                  <span style={{ color: "rgb(55, 105, 252)" }}>
                    {churnMonthlyRecurringRevenue.length > 0 ? `${AddCommaAmountNumber(churnMonthlyRecurringRevenue)} 원` : ""}
                  </span>
                </div>
                <div className="ContractInput_WidthBody_Copy">
                  Net MRR (순 MRR) |{" "}
                  <span style={{ color: "rgb(55, 105, 252)" }}>
                    {netMonthlyRecurringRevenue.length > 0 ? `${AddCommaAmountNumber(netMonthlyRecurringRevenue)} 원` : ""}
                  </span>
                </div>
                <div className="ContractInput_Divider" style={{ backgroundColor: "#E3E3E3" }} />
                <div className="ContractInput_WidthTitle_Copy" style={{ marginTop: "20px" }}>
                  월간 현금 흐름 현황
                </div>
                <div className="ContractInput_Divider" />
                <div className="ContractInput_WidthTitle_Copy" style={{ color: "#3769FC", marginBottom: "12px" }}>
                  월말 통장 잔액
                </div>
                <div className="ContractInput_WidthBody_Copy">
                  총 잔액 |{" "}
                  <span style={{ color: "rgb(55, 105, 252)" }}>{totalBalance.length > 0 ? `${AddCommaAmountNumber(totalBalance)} 원` : ""}</span>
                </div>
                <div className="ContractInput_WidthBody_Copy">
                  투자비 |{" "}
                  <span style={{ color: "rgb(55, 105, 252)" }}>{investmentFund.length > 0 ? `${AddCommaAmountNumber(investmentFund)} 원` : ""}</span>
                </div>
                <div className="ContractInput_WidthBody_Copy">
                  지원비 |{" "}
                  <span style={{ color: "rgb(55, 105, 252)" }}>{supportFund.length > 0 ? `${AddCommaAmountNumber(supportFund)} 원` : ""}</span>
                </div>
                <div className="ContractInput_Divider" style={{ backgroundColor: "#E3E3E3" }} />
                <div className="ContractInput_WidthTitle_Copy" style={{ color: "#3769FC", marginBottom: "12px" }}>
                  월 수입
                </div>
                <div className="ContractInput_WidthBody_Copy">
                  총 수입 |{" "}
                  <span style={{ color: "rgb(55, 105, 252)" }}>{totalIncome.length > 0 ? `${AddCommaAmountNumber(totalIncome)} 원` : ""}</span>
                </div>
                {incomeData.map((data, index) => (
                  <div className="ContractInput_WidthBody_Copy" key={data.ID}>
                    {data.Title || `수입 상세 항목 ${index + 1}`} |{" "}
                    <span style={{ color: "rgb(55, 105, 252)" }}>{data.Income.length > 0 ? `${AddCommaAmountNumber(data.Income)} 원` : ""}</span>
                  </div>
                ))}
                <div className="ContractInput_Divider" style={{ backgroundColor: "#E3E3E3" }} />
                <div className="ContractInput_WidthTitle_Copy" style={{ color: "#3769FC", marginBottom: "12px" }}>
                  월 지출
                </div>
                <div className="ContractInput_WidthBody_Copy">
                  총 지출 |{" "}
                  <span style={{ color: "rgb(55, 105, 252)" }}>{totalSpending.length > 0 ? `${AddCommaAmountNumber(totalSpending)} 원` : ""}</span>
                </div>
                <div className="ContractInput_WidthBody_Copy">
                  고정비 | <span style={{ color: "rgb(55, 105, 252)" }}>{fixedCost.length > 0 ? `${AddCommaAmountNumber(fixedCost)} 원` : ""}</span>
                </div>
                <div className="ContractInput_WidthBody_Copy">
                  변동비 |{" "}
                  <span style={{ color: "rgb(55, 105, 252)" }}>{variableCost.length > 0 ? `${AddCommaAmountNumber(variableCost)} 원` : ""}</span>
                </div>
                <div className="ContractInput_Divider" style={{ backgroundColor: "#E3E3E3" }} />
                <div className="ContractInput_WidthTitle_Copy" style={{ marginTop: "20px" }}>
                  월간 후속 투자 및 지원금
                </div>
                <div className="ContractInput_Divider" />
                <div className="ContractInput_WidthTitle_Copy" style={{ color: "#3769FC", marginBottom: "12px" }}>
                  후속 투자
                </div>
                {followUpInvestments.map((investment) => (
                  <div className="Report_Investment_BG" key={investment.ID}>
                    <div className="ContractInput_WidthBody_Copy" style={{ width: "100%" }}>
                      편드(투자사)명 | <span style={{ color: "rgb(55, 105, 252)" }}>{investment.FundName.length > 0 ? investment.FundName : ""}</span>
                    </div>
                    <div className="ContractInput_WidthBody_Copy" style={{ width: "100%" }}>
                      주식수 |{" "}
                      <span style={{ color: "rgb(55, 105, 252)" }}>
                        {investment.StockAmount.length > 0 ? `${AddCommaAmountNumber(investment.StockAmount)} 주` : ""}
                      </span>
                    </div>
                    <div className="ContractInput_WidthBody_Copy" style={{ width: "100%" }}>
                      주식 단가 |{" "}
                      <span style={{ color: "rgb(55, 105, 252)" }}>
                        {investment.StockPrice.length > 0 ? `${AddCommaAmountNumber(investment.StockPrice)} 원` : ""}
                      </span>
                    </div>
                    <div className="ContractInput_WidthBody_Copy" style={{ width: "100%" }}>
                      투자금 |{" "}
                      <span style={{ color: "rgb(55, 105, 252)" }}>
                        {investment.InvestmentMoney.length > 0 ? `${AddCommaAmountNumber(investment.InvestmentMoney)} 원` : ""}
                      </span>
                    </div>
                    <div className="ContractInput_WidthBody_Copy" style={{ width: "100%" }}>
                      Pre 투자금 |{" "}
                      <span style={{ color: "rgb(55, 105, 252)" }}>
                        {investment.PreInvestmentMoney.length > 0 ? `${AddCommaAmountNumber(investment.PreInvestmentMoney)} 원` : ""}
                      </span>
                    </div>
                    <div className="ContractInput_WidthBody_Copy" style={{ width: "100%" }}>
                      Post 투자금 |{" "}
                      <span style={{ color: "rgb(55, 105, 252)" }}>
                        {investment.PostInvestmentMoney.length > 0 ? `${AddCommaAmountNumber(investment.PostInvestmentMoney)} 원` : ""}
                      </span>
                    </div>
                    <div className="ContractInput_WidthBody_Copy" style={{ width: "100%" }}>
                      투자 단계 |{" "}
                      <span style={{ color: "rgb(55, 105, 252)" }}>
                        {investment.InvestmentPhase.length > 0 ? `${AddCommaAmountNumber(investment.InvestmentPhase)}` : ""}
                      </span>
                    </div>
                    <div className="ContractInput_WidthBody_Copy" style={{ width: "100%" }}>
                      투자일 |{" "}
                      <span style={{ color: "rgb(55, 105, 252)" }}>
                        {investment.InvestmentDate.length > 0 ? `${AddDotYYYYMMDD(investment.InvestmentDate)}` : ""}
                      </span>
                    </div>
                    <div className="ContractInput_WidthBody_Copy" style={{ width: "100%" }}>
                      비고 |
                    </div>
                    <div className="ContractInput_WidthBody_Copy" style={{ width: "100%", marginBottom: "0px" }}>
                      <span style={{ color: "rgb(55, 105, 252)" }}>{investment.Note.length > 0 ? investment.Note : ""}</span>
                    </div>
                  </div>
                ))}
                <div className="ContractInput_Divider" style={{ backgroundColor: "#E3E3E3" }} />
                <div className="ContractInput_WidthTitle_Copy" style={{ color: "#3769FC", marginBottom: "12px" }}>
                  지원
                </div>
                {subsidies.map((subside) => (
                  <div className="Report_Investment_BG" key={subside.ID}>
                    <div className="ContractInput_WidthBody_Copy" style={{ width: "100%" }}>
                      주관기관 |{" "}
                      <span style={{ color: "rgb(55, 105, 252)" }}>
                        {subside.HostOrganizationName.length > 0 ? subside.HostOrganizationName : ""}
                      </span>
                    </div>
                    <div className="ContractInput_WidthBody_Copy" style={{ width: "100%" }}>
                      주식수 | <span style={{ color: "rgb(55, 105, 252)" }}>{subside.ProgramName.length > 0 ? subside.ProgramName : ""}</span>
                    </div>
                    <div className="ContractInput_WidthBody_Copy" style={{ width: "100%" }}>
                      지원금 |{" "}
                      <span style={{ color: "rgb(55, 105, 252)" }}>
                        {subside.SupportFund.length > 0 ? `${AddCommaAmountNumber(subside.SupportFund)} 원` : ""}
                      </span>
                    </div>
                    <div className="ContractInput_WidthBody_Copy" style={{ width: "100%" }}>
                      지원일 |{" "}
                      <span style={{ color: "rgb(55, 105, 252)" }}>
                        {subside.SupportDate.length > 0 ? `${AddDotYYYYMMDD(subside.SupportDate)}` : ""}
                      </span>
                    </div>
                    <div className="ContractInput_WidthBody_Copy" style={{ width: "100%" }}>
                      비고 |
                    </div>
                    <div className="ContractInput_WidthBody_Copy" style={{ width: "100%", marginBottom: "0px" }}>
                      <span style={{ color: "rgb(55, 105, 252)" }}>{subside.Note.length > 0 ? subside.Note : ""}</span>
                    </div>
                  </div>
                ))}
                <div className="ContractInput_Divider" style={{ backgroundColor: "#E3E3E3" }} />
                <div className="ContractInput_WidthTitle_Copy" style={{ marginTop: "20px" }}>
                  월간 주요 경영 현안 공유
                </div>
                <div className="ContractInput_Divider" />
                <div className="ContractInput_WidthTitle_Copy" style={{ color: "#3769FC", marginBottom: "12px" }}>
                  월 주요 계획
                </div>
                <div className="ContractInput_WidthBody_Copy" style={{ lineHeight: "150%" }}>
                  {keyMonthlyPlan}
                </div>
                <div className="ContractInput_Divider" style={{ backgroundColor: "#E3E3E3" }} />
                <div className="ContractInput_WidthTitle_Copy" style={{ color: "#3769FC", marginBottom: "12px" }}>
                  공유 사안
                </div>
                <div className="ContractInput_WidthBody_Copy" style={{ lineHeight: "150%" }}>
                  {sharedIssue}
                </div>
                <div className="ContractInput_Divider" style={{ backgroundColor: "#E3E3E3" }} />
              </div>
            </div>
            <div className="ContractInput_VerticalDivider" />
            <div className="ContractInput_Right_CT">
              <div className="Contract_UserData_CT">
                <div className="ContractInput_InputField">
                  <div className="ContractInput_InputField_Label_CT">
                    <div className="Profile_Label_Copy">주주보고서 명</div>
                    <div className={reportName.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                  </div>
                  <input
                    className="ContractInput_LongInput_BG"
                    value={reportName}
                    onChange={(e) => setReportName(e.target.value)}
                    placeholder={`예시) ${userData.companyName} 2024년 09월 주주보고서`}
                    name="reportName"
                  />
                </div>
                <div className="ContractInput_InputField">
                  <div className="ContractInput_InputField_Label_CT">
                    <div className="Profile_Label_Copy">주주보고서 시기</div>
                    <div className={reportYearMonth.length === 6 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                  </div>
                  <input
                    type="text"
                    className="ContractInput_LongInput_BG"
                    value={reportYearMonth}
                    onChange={(e) => handleNumberInputChange(e.target.value, setReportYearMonth, 6)}
                    placeholder="2024년 09월 주주보고서인 경우 '202409' 입력 (YYYYMM 형식)"
                    name="reportYearMonth"
                  />
                </div>

                <div className="ContractInput_Divider" style={{ backgroundColor: "#E3E3E3", marginTop: "0px", marginBottom: "24px" }} />
                <div className="ContractInput_InputField">
                  <div className="ContractInput_InputField_Label_CT">
                    <div className="Profile_Label_Copy">신규 회원가입</div>
                    <div className={newMembershipRegistration.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                  </div>
                  <input
                    type="text"
                    className="ContractInput_LongInput_BG"
                    value={newMembershipRegistration}
                    onChange={(e) => handleNumberInputChange(e.target.value, setNewMembershipRegistration)}
                    placeholder="신규 회원가입자 수를 입력해주세요. (숫자만 입력)"
                    name="newMembershipRegistration"
                  />
                </div>
                <div className="ContractInput_InputField">
                  <div className="ContractInput_InputField_Label_CT">
                    <div className="Profile_Label_Copy">신규 유료 회원 수</div>
                    <div className={newPaidMember.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                  </div>
                  <input
                    type="text"
                    className="ContractInput_LongInput_BG"
                    value={newPaidMember}
                    onChange={(e) => handleNumberInputChange(e.target.value, setNewPaidMember)}
                    placeholder="신규 유료 회원 수를 입력해주세요. (숫자만 입력)"
                    name="newPaidMember"
                  />
                </div>
                <div className="ContractInput_InputField">
                  <div className="ContractInput_InputField_Label_CT">
                    <div className="Profile_Label_Copy">누적 유료 회원 수</div>
                    <div className={accumulatedPaidMember.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                  </div>
                  <input
                    type="text"
                    className="ContractInput_LongInput_BG"
                    value={accumulatedPaidMember}
                    onChange={(e) => handleNumberInputChange(e.target.value, setAccumulatedPaidMember)}
                    placeholder="누적 유료 회원 수를 입력해주세요. (숫자만 입력)"
                    name="accumulatedPaidMember"
                  />
                </div>
                <div className="ContractInput_Divider" style={{ backgroundColor: "#E3E3E3", marginTop: "0px", marginBottom: "24px" }} />
                <div className="ContractInput_InputField">
                  <div className="ContractInput_InputField_Label_CT">
                    <div className="Profile_Label_Copy">이탈 고객 수</div>
                    <div className={breakawayCustomer.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                  </div>
                  <input
                    type="text"
                    className="ContractInput_LongInput_BG"
                    value={breakawayCustomer}
                    onChange={(e) => handleNumberInputChange(e.target.value, setBreakawayCustomer)}
                    placeholder="이탈 고객 수를 입력해주세요. (숫자만 입력)"
                    name="breakawayCustomer"
                  />
                </div>
                <div className="ContractInput_InputField">
                  <div className="ContractInput_InputField_Label_CT">
                    <div className="Profile_Label_Copy">MRR (월간 반복 매출)</div>
                    <div className={monthlyRecurringRevenue.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                  </div>
                  <input
                    type="text"
                    className="ContractInput_LongInput_BG"
                    value={monthlyRecurringRevenue}
                    onChange={(e) => handleNumberInputChange(e.target.value, setMonthlyRecurringRevenue)}
                    placeholder="MRR (월간 반복 매출)을 입력해주세요. (숫자만 입력)"
                    name="monthlyRecurringRevenue"
                  />
                </div>
                <div className="ContractInput_InputField">
                  <div className="ContractInput_InputField_Label_CT">
                    <div className="Profile_Label_Copy">Churn MRR (이탈 MRR)</div>
                    <div className={churnMonthlyRecurringRevenue.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                  </div>
                  <input
                    type="text"
                    className="ContractInput_LongInput_BG"
                    value={churnMonthlyRecurringRevenue}
                    onChange={(e) => handleNumberInputChange(e.target.value, setChurnMonthlyRecurringRevenue)}
                    placeholder="Churn MRR (이탈 MRR)을 입력해주세요. (숫자만 입력)"
                    name="churnMonthlyRecurringRevenue"
                  />
                </div>
                <div className="ContractInput_InputField">
                  <div className="ContractInput_InputField_Label_CT">
                    <div className="Profile_Label_Copy">Net MRR (순 MRR)</div>
                    <div className={netMonthlyRecurringRevenue.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                  </div>
                  <input
                    type="text"
                    className="ContractInput_LongInput_BG"
                    value={netMonthlyRecurringRevenue}
                    onChange={(e) => handleNumberInputChange(e.target.value, setNetMonthlyRecurringRevenue)}
                    placeholder="Net MRR (순 MRR)을 입력해주세요. (숫자만 입력)"
                    name="netMonthlyRecurringRevenue"
                  />
                </div>
                <div className="ContractInput_Divider" style={{ backgroundColor: "#E3E3E3", marginTop: "0px", marginBottom: "24px" }} />
                <div className="ContractInput_InputField">
                  <div className="ContractInput_InputField_Label_CT">
                    <div className="Profile_Label_Copy">총 잔액</div>
                    <div className={totalBalance.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                  </div>
                  <input
                    type="text"
                    className="ContractInput_LongInput_BG"
                    value={totalBalance}
                    onChange={(e) => handleNumberInputChange(e.target.value, setTotalBalance)}
                    placeholder="총 잔액을 입력해주세요. (숫자만 입력)"
                    name="totalBalance"
                  />
                </div>
                <div className="ContractInput_InputField">
                  <div className="ContractInput_InputField_Label_CT">
                    <div className="Profile_Label_Copy">투자비</div>
                    <div className={investmentFund.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                  </div>
                  <input
                    type="text"
                    className="ContractInput_LongInput_BG"
                    value={investmentFund}
                    onChange={(e) => handleNumberInputChange(e.target.value, setInvestmentFund)}
                    placeholder="이번달 투자비를 입력해주세요. (숫자만 입력)"
                    name="investmentFund"
                  />
                </div>
                <div className="ContractInput_InputField">
                  <div className="ContractInput_InputField_Label_CT">
                    <div className="Profile_Label_Copy">지원비</div>
                    <div className={supportFund.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                  </div>
                  <input
                    type="text"
                    className="ContractInput_LongInput_BG"
                    value={supportFund}
                    onChange={(e) => handleNumberInputChange(e.target.value, setSupportFund)}
                    placeholder="이번달 지원비를 입력해주세요. (숫자만 입력)"
                    name="supportFund"
                  />
                </div>
                <div className="ContractInput_Divider" style={{ backgroundColor: "#E3E3E3", marginTop: "0px", marginBottom: "24px" }} />
                <div className="ContractInput_InputField">
                  <div className="ContractInput_InputField_Label_CT">
                    <div className="Profile_Label_Copy">총 수입</div>
                    <div className={totalIncome.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                  </div>
                  <input
                    type="text"
                    className="ContractInput_LongInput_BG"
                    value={totalIncome}
                    onChange={(e) => handleNumberInputChange(e.target.value, setTotalIncome)}
                    placeholder="이번달 총 수입을 입력해주세요. (숫자만 입력)"
                    name="totalIncome"
                  />
                </div>
                {incomeData.map((incomeData, index) => (
                  <div key={incomeData.ID}>
                    <div className="ContractInput_Divider" style={{ backgroundColor: "#E3E3E3", marginTop: "0px", marginBottom: "24px" }} />
                    <div
                      style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: "24px" }}
                    >
                      <div className="Profile_Label_Copy" style={{ color: "#3182f6" }}>
                        수입 상세 항목 {index + 1}
                      </div>
                      <button
                        onClick={() => handleDeleteIncomeDats(index)}
                        style={{ margin: "0px", padding: "0px", backgroundColor: "transparent", borderStyle: "none", cursor: "pointer" }}
                      >
                        <Icon name={"Close"} size={"20"} color={"#FF7373"} />
                      </button>
                    </div>

                    <div className="ContractInput_InputField">
                      <div className="ContractInput_InputField_Label_CT">
                        <div className="Profile_Label_Copy">수입 상세항목 명</div>
                        <div className={incomeData.Title.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                      </div>
                      <input
                        type="text"
                        name="Title"
                        className="ContractInput_LongInput_BG"
                        placeholder="수입 상세 항목 이름을 입력해주세요."
                        value={incomeData.Title}
                        onChange={(e) => handleIncomeDataInputChange(e, index)}
                      />
                    </div>
                    <div className="ContractInput_InputField">
                      <div className="ContractInput_InputField_Label_CT">
                        <div className="Profile_Label_Copy">금액</div>
                        <div className={incomeData.Income.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                      </div>
                      <input
                        type="text"
                        name="Income"
                        className="ContractInput_LongInput_BG"
                        placeholder="상세 항목의 금액을 입력해주세요. (숫자만 입력)"
                        value={incomeData.Income}
                        onChange={(e) => handleIncomeDataInputChange(e, index)}
                      />
                    </div>
                  </div>
                ))}
                <button
                  className="ContractInput_ConfirmButton_BG"
                  type="button"
                  onClick={addIncomeData}
                  style={{ marginTop: "0px", marginBottom: "0px", backgroundColor: "#E7EFFF", color: "rgb(55, 105, 252)" }}
                >
                  수입 상세 항목 추가
                </button>
                <div className="ContractInput_Divider" style={{ backgroundColor: "#E3E3E3", marginBottom: "24px", marginTop: "24px" }} />

                <div className="ContractInput_InputField">
                  <div className="ContractInput_InputField_Label_CT">
                    <div className="Profile_Label_Copy">총 지출</div>
                    <div className={totalSpending.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                  </div>
                  <input
                    type="text"
                    className="ContractInput_LongInput_BG"
                    value={totalSpending}
                    onChange={(e) => handleNumberInputChange(e.target.value, setTotalSpending)}
                    placeholder="이번달 총 지출을 입력해주세요. (숫자만 입력)"
                    name="totalSpending"
                  />
                </div>
                <div className="ContractInput_InputField">
                  <div className="ContractInput_InputField_Label_CT">
                    <div className="Profile_Label_Copy">고정비</div>
                    <div className={fixedCost.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                  </div>
                  <input
                    type="text"
                    className="ContractInput_LongInput_BG"
                    value={fixedCost}
                    onChange={(e) => handleNumberInputChange(e.target.value, setFixedCost)}
                    placeholder="이번달 고정비를 입력해주세요. (숫자만 입력)"
                    name="fixedCost"
                  />
                </div>
                <div className="ContractInput_InputField">
                  <div className="ContractInput_InputField_Label_CT">
                    <div className="Profile_Label_Copy">변동비</div>
                    <div className={variableCost.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                  </div>
                  <input
                    type="text"
                    className="ContractInput_LongInput_BG"
                    value={variableCost}
                    onChange={(e) => handleNumberInputChange(e.target.value, setVariableCost)}
                    placeholder="이번달 변동비를 입력해주세요. (숫자만 입력)"
                    name="variableCost"
                  />
                </div>
                <div className="ContractInput_Divider" style={{ backgroundColor: "#E3E3E3", marginTop: "0px", marginBottom: "24px" }} />
                {followUpInvestments.map((investment, index) => (
                  <div key={investment.ID}>
                    <div
                      style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: "24px" }}
                    >
                      <div className="Profile_Label_Copy" style={{ color: "#3182f6" }}>
                        {/* 후속 투자 {index + 1} */}
                        후속 투자
                      </div>
                      <button
                        onClick={() => handleDeleteInvestment(index)}
                        style={{ margin: "0px", padding: "0px", backgroundColor: "transparent", borderStyle: "none", cursor: "pointer" }}
                      >
                        <Icon name={"Close"} size={"20"} color={"#FF7373"} />
                      </button>
                    </div>

                    <div className="ContractInput_InputField">
                      <div className="ContractInput_InputField_Label_CT">
                        <div className="Profile_Label_Copy">편드(투자사) 명</div>
                        <div className={investment.FundName.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                      </div>
                      <input
                        type="text"
                        name="FundName"
                        className="ContractInput_LongInput_BG"
                        placeholder="편드(투자사) 이름을 입력해주세요."
                        value={investment.FundName}
                        onChange={(e) => handleInvestmentInputChange(e, index)}
                      />
                    </div>
                    <div className="ContractInput_InputField">
                      <div className="ContractInput_InputField_Label_CT">
                        <div className="Profile_Label_Copy">주식 수</div>
                        <div className={investment.StockAmount.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                      </div>
                      <input
                        type="text"
                        name="StockAmount"
                        className="ContractInput_LongInput_BG"
                        placeholder="주식 수를 입력해주세요. (숫자만 입력)"
                        value={investment.StockAmount}
                        onChange={(e) => handleInvestmentInputChange(e, index)}
                      />
                    </div>
                    <div className="ContractInput_InputField">
                      <div className="ContractInput_InputField_Label_CT">
                        <div className="Profile_Label_Copy">주식 단가</div>
                        <div className={investment.StockPrice.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                      </div>
                      <input
                        type="text"
                        name="StockPrice"
                        className="ContractInput_LongInput_BG"
                        placeholder="주식 가격을 입력해주세요. (숫자만 입력)"
                        value={investment.StockPrice}
                        onChange={(e) => handleInvestmentInputChange(e, index)}
                      />
                    </div>
                    <div className="ContractInput_InputField">
                      <div className="ContractInput_InputField_Label_CT">
                        <div className="Profile_Label_Copy">투자 금액</div>
                        <div className={investment.InvestmentMoney.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                      </div>
                      <input
                        type="text"
                        name="InvestmentMoney"
                        className="ContractInput_LongInput_BG"
                        placeholder="투자 금액을 입력해주세요. (숫자만 입력)"
                        value={investment.InvestmentMoney}
                        onChange={(e) => handleInvestmentInputChange(e, index)}
                      />
                    </div>
                    <div className="ContractInput_InputField">
                      <div className="ContractInput_InputField_Label_CT">
                        <div className="Profile_Label_Copy">Pre 투자 금액</div>
                      </div>
                      <input
                        type="text"
                        name="PreInvestmentMoney"
                        className="ContractInput_LongInput_BG"
                        placeholder="Pre 투자 금액을 입력해주세요. (숫자만 입력)"
                        value={investment.PreInvestmentMoney}
                        onChange={(e) => handleInvestmentInputChange(e, index)}
                      />
                    </div>
                    <div className="ContractInput_InputField">
                      <div className="ContractInput_InputField_Label_CT">
                        <div className="Profile_Label_Copy">Post 투자 금액</div>
                      </div>
                      <input
                        type="text"
                        name="PostInvestmentMoney"
                        className="ContractInput_LongInput_BG"
                        placeholder="Pre 투자 금액을 입력해주세요. (숫자만 입력)"
                        value={investment.PostInvestmentMoney}
                        onChange={(e) => handleInvestmentInputChange(e, index)}
                      />
                    </div>
                    <div className="ContractInput_InputField">
                      <div className="ContractInput_InputField_Label_CT">
                        <div className="Profile_Label_Copy">투자 단계</div>
                        <div
                          className={
                            reportData.MonthlyFollowUpInvestmentsAndSubsidies.FollowUpInvestments[index].InvestmentPhase.length > 0
                              ? "Required_Dot_Blue"
                              : "Required_Dot_Red"
                          }
                        />
                      </div>
                      <Select
                        options={investmentPhaseOptions}
                        onChange={(selectedOption) => handleInvestmentPhaseInputChange("InvestmentPhase", selectedOption[0].value, index)}
                        values={investmentPhaseOptions.filter(
                          (option) => option.value === reportData.MonthlyFollowUpInvestmentsAndSubsidies.FollowUpInvestments[index].InvestmentPhase
                        )}
                        style={{
                          width: 420,
                          height: 44,
                          fontWeight: 400,
                          fontFamily: "Pretendard",
                          fontSize: 14,
                          fontColor: "rgb(70, 70, 70)",
                          borderRadius: 8,
                          borderStyle: "solid",
                          borderWidth: 1,
                          borderColor: "rgb(227, 227, 227)",
                          paddingLeft: 8,
                          paddingRight: 8,
                        }}
                      />
                    </div>
                    <div className="ContractInput_InputField">
                      <div className="ContractInput_InputField_Label_CT">
                        <div className="Profile_Label_Copy">투자일</div>
                        <div className={investment.InvestmentDate.length === 8 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                      </div>
                      <input
                        type="text"
                        name="InvestmentDate"
                        className="ContractInput_LongInput_BG"
                        placeholder="투자일을 입력해주세요. (YYYYMMDD 형식)"
                        value={investment.InvestmentDate}
                        onChange={(e) => handleInvestmentInputChange(e, index)}
                      />
                    </div>
                    <div className="ContractInput_InputField">
                      <div className="ContractInput_InputField_Label_CT">
                        <div className="Profile_Label_Copy">비고</div>
                      </div>
                      <textarea
                        name="Note"
                        id={`Note${index}`}
                        className="Report_TextArea_BG"
                        value={investment.Note}
                        onChange={(e) => handleInvestmentInputChange(e, index)}
                      />
                    </div>

                    <div className="ContractInput_Divider" style={{ backgroundColor: "#E3E3E3", marginTop: "24px", marginBottom: "24px" }} />
                  </div>
                ))}
                {followUpInvestments.length === 0 && (
                  <>
                    <button
                      className="ContractInput_ConfirmButton_BG"
                      type="button"
                      onClick={addInvestment}
                      style={{ marginTop: "0px", marginBottom: "0px", backgroundColor: "#E7EFFF", color: "rgb(55, 105, 252)" }}
                    >
                      후속 투자 추가
                    </button>
                    <div className="ContractInput_Divider" style={{ backgroundColor: "#E3E3E3", marginBottom: "24px", marginTop: "24px" }} />
                  </>
                )}

                {subsidies.map((subside, index) => (
                  <div key={subside.ID}>
                    <div
                      style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: "24px" }}
                    >
                      <div className="Profile_Label_Copy" style={{ color: "#3182f6" }}>
                        {/* 지원 {index + 1} */}
                        지원
                      </div>
                      <button
                        onClick={() => handleDeleteSubside(index)}
                        style={{ margin: "0px", padding: "0px", backgroundColor: "transparent", borderStyle: "none", cursor: "pointer" }}
                      >
                        <Icon name={"Close"} size={"20"} color={"#FF7373"} />
                      </button>
                    </div>

                    <div className="ContractInput_InputField">
                      <div className="ContractInput_InputField_Label_CT">
                        <div className="Profile_Label_Copy">주관기관 명</div>
                        <div className={subside.HostOrganizationName.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                      </div>
                      <input
                        type="text"
                        name="HostOrganizationName"
                        className="ContractInput_LongInput_BG"
                        placeholder="주관기관 이름을 입력해주세요."
                        value={subside.HostOrganizationName}
                        onChange={(e) => handleSubsidiesInputChange(e, index)}
                      />
                    </div>
                    <div className="ContractInput_InputField">
                      <div className="ContractInput_InputField_Label_CT">
                        <div className="Profile_Label_Copy">프로그램 명</div>
                        <div className={subside.ProgramName.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                      </div>
                      <input
                        type="text"
                        name="ProgramName"
                        className="ContractInput_LongInput_BG"
                        placeholder="투자 지원금 프로그램 이름을 입력해주세요."
                        value={subside.ProgramName}
                        onChange={(e) => handleSubsidiesInputChange(e, index)}
                      />
                    </div>
                    <div className="ContractInput_InputField">
                      <div className="ContractInput_InputField_Label_CT">
                        <div className="Profile_Label_Copy">지원금</div>
                        <div className={subside.SupportFund.length > 0 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                      </div>
                      <input
                        type="text"
                        name="SupportFund"
                        className="ContractInput_LongInput_BG"
                        placeholder="지원금 금액을 입력해주세요. (숫자만 입력)"
                        value={subside.SupportFund}
                        onChange={(e) => handleSubsidiesInputChange(e, index)}
                      />
                    </div>
                    <div className="ContractInput_InputField">
                      <div className="ContractInput_InputField_Label_CT">
                        <div className="Profile_Label_Copy">지원일</div>
                        <div className={subside.SupportDate.length === 8 ? "Required_Dot_Blue" : "Required_Dot_Red"} />
                      </div>
                      <input
                        type="text"
                        name="SupportDate"
                        className="ContractInput_LongInput_BG"
                        placeholder="지원 받은 날짜를 입력해주세요. (YYYYMMDD 형식)"
                        value={subside.SupportDate}
                        onChange={(e) => handleSubsidiesInputChange(e, index)}
                      />
                    </div>
                    <div className="ContractInput_InputField">
                      <div className="ContractInput_InputField_Label_CT">
                        <div className="Profile_Label_Copy">비고</div>
                      </div>
                      <textarea
                        name="Note"
                        id={`Note${index}`}
                        className="Report_TextArea_BG"
                        value={subside.Note}
                        onChange={(e) => handleSubsidiesInputChange(e, index)}
                      />
                    </div>

                    <div className="ContractInput_Divider" style={{ backgroundColor: "#E3E3E3", marginTop: "24px", marginBottom: "24px" }} />
                  </div>
                ))}
                {subsidies.length === 0 && (
                  <>
                    <button
                      className="ContractInput_ConfirmButton_BG"
                      type="button"
                      onClick={addSubside}
                      style={{ marginTop: "0px", marginBottom: "0px", backgroundColor: "#E7EFFF", color: "rgb(55, 105, 252)" }}
                    >
                      지원금 추가
                    </button>
                    <div className="ContractInput_Divider" style={{ backgroundColor: "#E3E3E3", marginBottom: "24px", marginTop: "24px" }} />
                  </>
                )}

                <div className="ContractInput_InputField">
                  <div className="ContractInput_InputField_Label_CT">
                    <div className="Profile_Label_Copy">월 주요 계획</div>
                  </div>
                  <textarea
                    name="KeyMonthlyPlan"
                    id="KeyMonthlyPlan"
                    className="Report_TextArea_BG"
                    value={keyMonthlyPlan}
                    onChange={(e) => setKeyMonthlyPlan(e.target.value)}
                  />
                </div>
                <div className="ContractInput_InputField">
                  <div className="ContractInput_InputField_Label_CT">
                    <div className="Profile_Label_Copy">공유 사안</div>
                  </div>
                  <textarea
                    name="SharedIssue"
                    id="SharedIssue"
                    className="Report_TextArea_BG"
                    value={sharedIssue}
                    onChange={(e) => setSharedIssue(e.target.value)}
                  />
                </div>
                <button className="ContractInput_ConfirmButton_BG" type="button" onClick={() => onClickConfirm(reportData)}>
                  작성 완료
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default InvestorCompanyShareholderReport;
